const isStaging = window.location.hostname.includes("staging");

const oidcConfig = {
    authority: isStaging
        ? "https://auth-staging.luniwave.com/realms/Luniwave/"
        : process.env.REACT_APP_OIDC_AUTHORITY ||
          "http://localhost:8080/realms/Luniwave/",
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID || "react-auth",
    redirect_uri: isStaging
        ? "https://app-staging.luniwave.com/redirect"
        : process.env.REACT_APP_OIDC_REDIRECT_URI ||
          "http://localhost:3000/redirect",
    post_logout_redirect_uri: isStaging
        ? "https://app-staging.luniwave.com/redirect"
        : process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI ||
          "http://localhost:3000/redirect",
    response_type: "code", // Utilise 'code' pour PKCE
    scope: "openid profile email",
    // Activer PKCE
    usePkce: true,
};

console.log(isStaging);
console.log(oidcConfig);

export default oidcConfig;
